





































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OCompanyEditData: () =>
      import("@/components/organisms/event/company/o-company-edit-data.vue"),
    OCompanyEditRepresentatives: () =>
      import(
        "@/components/organisms/event/company/o-company-edit-representatives.vue"
      ),
    OCompanyEditStand: () =>
      import("@/components/organisms/event/company/o-company-edit-stand.vue"),
    OCompanyEditServices: () =>
      import(
        "@/components/organisms/event/company/o-company-edit-services.vue"
      ),
    OCompanyDocuments: () =>
      import("@/components/organisms/event/company/o-company-documents.vue"),
    OCompanyPlan: () =>
      import("@/components/organisms/event/company/o-company-plan.vue"),
  },

  setup() {
    return {};
  },
});
